<template>
  <div class="send-ticket-dialog">
    <v-icon
      class="close-icon"
      :size="30"
      color="black"
      @click="$emit('onClose')"
    >
      close
    </v-icon>
    <div class="dialog-title">{{ $t("send_ticket_title") }}</div>

    <div class="dialog-body">
      <p class="description">
        {{ $t("send_ticket_description") }}
      </p>

      <v-form
        class="send-ticket-form"
        ref="sendTicketForm"
        lazy-validation
        @submit.prevent=""
      >
        <span class="input-label">{{ $t("enter_email") }}</span>
        <base-input
          v-model.trim="sendTicketForm.email"
          type="email"
          class="email-field"
          :class="{ 'has-errors': !!errors.email }"
          :rules="rules.email"
          validate-on-blur
          filled
          height="36"
          :error="!!errors.email"
          :error-messages="errors.email"
        />
      </v-form>
    </div>

    <div class="dialog-footer">
      <base-button
        class="action-button"
        btn-type="mem-primary"
        @click="onSendTicketForm"
      >
        {{ $t("done") }}
      </base-button>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/base/BaseButton";
import BaseInput from "@/components/base/BaseInput";
import { emailValidator, requireValidator } from "@/common/validators";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "SendTicketDialog",
  components: { BaseButton, BaseInput },
  data() {
    return {
      sendTicketForm: {
        email: "",
      },
      errors: {},
      rules: {
        email: [
          (val) => requireValidator(val) || this.$t("field_required"),
          (val) => emailValidator(val) || this.$t("invalid_email"),
        ],
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["currentTicket"]),
  },
  methods: {
    ...mapActions(["updateTicketRecipient", "handleToolbarNotification"]),
    async onSendTicketForm() {
      if (this.loading) return;

      this.errors = {};
      const { sendTicketForm } = this.$refs;
      let isFormValid = sendTicketForm?.validate();
      if (!isFormValid) return;

      this.loading = true;

      try {
        await this.updateTicketRecipient({
          barcode: this.currentTicket.barcode,
          model: {
            email: this.sendTicketForm.email,
          },
        });
        this.errors = {}; // ?

        this.handleToolbarNotification({
          type: "success",
          text: "ticket_will_update",
          isVisible: true,
        });
        setTimeout(() => {
          this.handleToolbarNotification({ isVisible: false });
        }, 3000);
        
        this.sendTicketForm.email = "";
        this.$emit("onClose");
      } catch (e) {
        console.error(e);
        this.errors = {
          email: this.$t("server_error"),
        };
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.send-ticket-dialog {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-self: center;
  position: relative;
  padding: 32px 32px 26px 32px;
  background-color: #ffffff;
  box-shadow: 0 10px 14px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  color: #000000;
  @media screen and (max-width: 499px) {
    padding: 24px 12px 20px 12px;
    border-radius: 5px;
  }

  .close-icon {
    position: absolute;
    top: 9px;
    right: 9px;

    &:hover,
    &:focus {
      &:after {
        opacity: 0;
      }
    }
  }

  .dialog-title {
    width: 100%;
    padding: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    color: #000000;
    margin-top: 8px;
  }
  .dialog-body {
    margin-top: 28px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding: 0;
    text-align: left;
    color: black;
    @media screen and (max-width: 499px) {
      margin-top: 18px;
    }

    .description {
      margin-bottom: 26px;
      max-width: 75%;
    }

    .input-label {
      display: inline-block;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      text-transform: uppercase;
      color: #9d9d9d;
      margin-bottom: 12px;
    }

    .email-field {
      ::v-deep {
        .v-input__slot {
          min-height: 48px;
          border-radius: 4px;

          &::before,
          &::after {
            display: none;
          }
          .v-text-field__slot {
            display: flex;
            flex-direction: row;
            align-items: center;
            .v-label {
              display: none;
            }
            input {
              margin-top: 0;
            }
          }
        }
        .v-text-field__details {
          padding-left: 0;
        }
      }

      &.error--text {
        ::v-deep {
          .v-input__slot {
            border: 1px solid #ff0707;
          }
        }
      }
    }
  }
  .dialog-footer {
    width: 100%;
    margin-top: 2px;
    // margin-bottom: 5px;
    padding-top: 0;
    padding-bottom: 0;
    justify-content: flex-start;

    .action-button {
      height: 36px;
      font-size: 12px;
      padding: 0 21px;
    }

    .action-button + .action-button {
      margin-left: 12px;
    }
  }
}
</style>
